<template>
  <div>
    <!-- <v-stepper non-linear class="shadow-none">
    <v-stepper-header class="stepper">
      <v-stepper-step
        v-for="(item, i) in items"
        :key="i"
        editable
        :step="i + 1"
        @click="handleClick(i + 1)"
      >
        <span class="text-center w-100">
          {{ item.title }}
        </span>
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper> -->
    <div
      class="stepper-wizard d-sm-flex align-items-center justify-content-between"
    >
      <div v-for="(item, i) in items" :key="i">
        <div
          :class="`stepper-item ${i + 1 === value && 'active'}`"
          @click="handleClick(i + 1)"
        >
          <img :src="require(`@/assets/img/realisasi/${i + 1}.svg`)" alt="" />

          <p>{{ i + 1 }}. {{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoleculeWizardStepper",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleClick(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style lang="scss">
.stepper-wizard {
  .stepper-item {
    cursor: pointer;
    text-align: center;

    p {
      font-weight: 500;
      color: #50566a;
    }

    img {
      width: 3.5vw;
      margin-bottom: 0.8rem;
      filter: invert(82%) sepia(23%) saturate(140%) hue-rotate(187deg)
        brightness(86%) contrast(89%);
    }
  }

  .active {
    p {
      font-weight: 600;
      color: #004799;
    }

    img {
      filter: invert(18%) sepia(31%) saturate(7071%) hue-rotate(202deg)
        brightness(92%) contrast(101%);
    }
  }
}

@media screen and (max-width: 500px) {
  .stepper-wizard {
    .stepper-item {
      text-align: left;
    }
  }
}
</style>
